:root {
    --background: white;
    --primary: white;
    --secondary: white;
    --pageButtons: blue;
    --pageButtonsText: white;
    --hover: blue;
    --elementButtons: blue;
    --elementButtonsText: white;
    --links: white;
    --loadingSpinner: blue;
    --primaryText: black;
    --secondaryText: black;
    --border: black;
    --tableHeaderText: black;
    background-color: var(--background);
}

.search-box-container {
    max-width: 100% !important;
    flex-grow: 1 !important;
}

.grid-container-header {
    text-align: center;
    font-weight: bold;
    margin-bottom: 14px;
}

.grid-container div:hover {
    cursor: pointer;
    background-color: var(--secondary);
}
 
.grid-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;
    width: 1050px;
}

.grid-container>div {
    border: 2px solid var(--border);
    transition-duration: 0.4s;
    padding: 20px;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100px;
    margin: 15px;
    border-radius: 4px;
    width: 450px;
}

.page {
    margin: 20px auto;
    width: 90%;
    min-width: 292px;
    font-size: 20px;
}

.page-image-center {
    text-align: center;
    border-radius: 8px 8px 0 0;
}

.company-logo {
    width: 100%;
    max-width: 700px;
    align-self: center;
    margin-left: auto;
    margin-right: auto;
}

.page-input {
    display: block;
    margin: 0 auto 0px auto;
    height: 25px;
    width: 80%;
    max-width: 300px;
    font-size: 20px;
    padding: 12px;
    border: 2px solid;
    border-radius: 8px;
    transition-duration: 0.4s;
    text-align: left;
}

.page-input:focus {
    outline: none;
    box-shadow: 0px 0px 4px var(--primary);
}

/* The dropdown container */
.dropdown {
    float: left;
    overflow: hidden;
    height: auto;
    width: auto;
}

/* Dropdown content (hidden by default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
    float: none;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
}

/* Add a grey background color to dropdown links on hover */
.dropdown-content a:hover {
    background-color: #ddd;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
}

/* Dropdown button */
.dropdown .dropbtn {
    font-size: 16px;
    justify-content: center;
    border: none;
    outline: none;
    color: white;
    padding: 14px 14px;
    background-color: inherit;
    font-family: inherit;
    /* Important for vertical align on mobile phones */
    margin: 0;
    /* Important for vertical align on mobile phones */
}

.faq-textarea {
    display: block;
    margin: 10px auto 0px auto;
    max-width: 600px;
    height: auto;
    font-size: 20px;
    font-family: Arial;
    padding: 12px;
    border: 2px solid;
    border-radius: 8px;
    transition-duration: 0.4s;
    text-align: left;
}

.faq-buttons {
    /* display: inline-block; */
    vertical-align: top;
}

.page-button {
    display: block;
    margin: 20px auto;
    width: 100%;
    max-width: 325px;
    padding: 12px;
    border: 2px hidden;
    border-radius: 8px;
    font-size: 18px;
    background-color: var(--pageButtons);
    color: var(--pageButtonsText);
    transition-duration: 0.4s;
    cursor: pointer;
}

.page-button:hover {
    background-color: var(--hover);
}

.page-button:disabled {
    opacity: 0.65;
    cursor: not-allowed;
}

.page-button:disabled:hover {
    background-color: grey;
}

.page-text-header {
    text-align: center;
    margin-bottom: 15px;
    display: block;
    font-size: 30px;
    font-weight: bold;
    padding-top: 35px;
    padding-bottom: 25px;
    border-radius: 0 0 5px 5px;
    background-color: var(--secondary);
    border-radius: 0 0 8px 8px;
    border-bottom: 2px solid black;
}

.page-text {
    text-align: center;
    margin-top: 25px;
    margin-bottom: 15px;
    display: block;
}

label.page-text {
    margin-top: 25px;
    margin-bottom: 15px;
}

.page-link {
    text-align: center;
    margin: 5px 0;
    color: var(--links);
    transition-duration: 0.4s;
}

.page-link:hover {
    color: var(--secondary);
    cursor: pointer;
}

.page-form {
    margin-top: 40px;
    border: 1px solid var(--border);
    border-radius: 8px;
    /* box-shadow: 0px 10px 10px #35353550; */
    padding: 0 40px 40px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--primary);
}

.page-inner-form {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;

}

.success-message {
    color: green !important;
}

.faq-text {
    font-size: 16px;
    margin: 10px auto 20px auto;
    width: 90%;
    max-width: 400px;
    text-align: center;
    word-wrap: normal;
}

.assign-module {
    justify-content: center;
    display: flex;
    align-items: center;
}

.assign-sub {
    justify-content: center;
    display: flex;
    align-items: center;
}

.page-error {
    font-size: 16px;
    color: red;
    margin: 10px auto 20px auto;
    width: 100%;
    text-align: center;
    font-weight: bold;
    word-wrap: normal;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-error {
    margin-bottom: 5px !important;
    word-wrap: normal;
}

.reset-api-error {
    font-size: 16px;
    color: red;
    margin: 10px auto 20px auto;
    width: 50%;
    text-align: center;
    font-weight: bold;
    word-wrap: normal;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.page-error-header {
    text-align: center;
    word-wrap: normal;
}

.login-error-header {
    text-align: center;
    word-wrap: normal;
}

.page-error-body {
    width: 25%;
    text-align: left;
    font-weight: normal;
    word-wrap: break-spaces;
}

.reset-error-body {
    color: red;
    text-align: left;
    font-weight: normal;
    word-wrap: break-spaces;
}

.settings-error-body {
    text-align: left;
    font-weight: normal;
    word-wrap: break-spaces;
}

.faq-list {
    list-style-type: none;
    padding: 0;
}

.faq-pair {
    text-align: center;
    background-color: var(--pageButtons);
    color: var(--primary) !important;
    padding: 20px;
    border: none;
    border-radius: 8px;
    margin: 15px auto 0px auto;
}

.faq-edit-pair {
    text-align: center;
    background-color: var(--pageButtons);
    color: var(--primary) !important;
    padding: 20px;
    border: none;
    border-radius: 8px;
    margin: 15px auto 0px auto;
}

.banner {
    background-color: var(--pageButtons) !important;
    color: var(--pageButtonsText) !important;
    border-radius: 4px;
}

.bannerDismiss {
    color: var(--primary) !important;
}

.page-header {
    width: 100%;
    border-radius: 8px;
}

.page-body {
    width: 100%;
    margin: auto;
    padding-top: 30px;
    min-width: 1400px;
}

.welcome-message-container {
    float: left;
}

.welcome-message {
    margin-top: 10px;
    margin-left: 20px;
    padding-top: 1px;
    padding-left: 6px;
    font: 400 13.3333px Arial;
}

.welcome-messages {
    margin-top: 10px;
    margin-left: 20px;
    padding-top: 1px;
    padding-left: 6px;
    font: 400 20px Arial;
}

.logo-header {
    background-color: white;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.logo-container {
    display: block;
    align-content: center;
    text-align: center;
}

.toolbar {
    float: right;
    text-align: right;
}

.toolbar-button {
    background-color: transparent;
    border: none;
    margin-top: 10px;
    margin-right: 10px;
}

.toolbar-button:hover {
    cursor: pointer;
}

.toolbar-icon {
    padding: 0px 4px 1px 0px;
    margin-right: 8px;
}

.dashboard-container {
    border: 1px solid var(--border);
    border-radius: 8px !important;
    /* box-shadow: 0px 10px 10px #35353550 hidden; */
    background-color: var(--primary);
    margin-top: 10px;
    overflow: hidden;
}

.account-list-header {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    padding: 10px 0;
    color: var(--primaryText);
}

.account-list-item {
    border-top: 1px solid var(--border);
    transition-duration: 0.4s;
    padding: 15px;
    height: 65px;
}

.account-list-item:hover {
    cursor: pointer;
    background-color: var(--secondary);
}

.menu-button:hover {
    cursor: pointer;
    color: var(--secondary);
}

.selected-account {
    background-color: var(--secondary);
    color: black !important;
}

.selected-account:hover {
    color: black !important;
}

.transactions-button {
    float: left;
    background-color: transparent;
    border: none;
    text-align: left;
    margin-top: 0;
    transition-duration: .4s;
}

.transactions-button:hover {
    cursor: pointer;
    color: var(--secondary);
}

.italics {
    font-style: italic;
    font-size: 12px;
}

.accounts-col {
    float: left;
    width: 50%;
    font-size: 16px;
    pointer-events: none;
}

.sso-module-text {
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    height: 95px;
    word-wrap: normal;
}

.transactions-container {
    border: 2px hidden;
    border-radius: 8px;
    box-shadow: none;
    background-color: var(--secondary);
}

.account-type-transactions {
    font-size: 20px;
    font-weight: bold;
    text-transform: capitalize;
}

.account-number-transactions {
    font-size: 20px;
    font-weight: bold;
}

.account-info {
    width: 20%;
    float: left;
}

.statements {
    width: 200px;
    float: left;
    background-color: transparent;
    border: none;
    text-align: left;
    margin-top: 0;
}

.transfers {
    width: 20%;
    float: left;
    background-color: transparent;
    border: none;
    text-align: left;
    margin-top: 0;
}

.menu-container {
    font-size: 14px;
    margin: 10px;
    float: right;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.menu {
    width: 100%;
    height: 30px;
    border: 1px solid black;
    background-color: var(--primary);
    text-align: left;
    margin-top: 0;
    float: right;
}

.menu-button {
    width: 100%;
    height: 30px;
    text-align: left;
    border: 1px solid black;
    background-color: var(--primary);
    margin-top: 0px;
    transition-duration: .4s;
}

.filter-dropdown {
    height: 30px;
    padding: 4px;
    background-color: var(--primary);
    border-radius: 4px;
    border: 1px solid #ccc;
}

.filter-caret {
    float: right;
    padding: 2px 0 0 0;
}

.search-box {
    font-size: 14px;
    margin: 0px;
    float: right;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0px;
}

.search-container {
    width: 100%;
    padding: 10px;
}

.search-box input[type="text"] {
    font-size: 14px;
    padding: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.transactions-header {
    padding: 10px;
}

.transactions-body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 20px;

}

.displayFlexCenter {
    display: flex;
    justify-content: center;
    padding: 20px;
    box-shadow: none !important;
}

.transactions-list-header {
    height: 40px;
    width: 100%;
    min-width: 625px;
    float: left;
    border: 2px black solid;
}

.transactions-list {
    height: 420px;
    width: 100%;
    min-width: 625px;
    float: left;
    border-left: 2px black solid;
    border-right: 2px black solid;
    font-size: 15px;
}

.account-list-text {
    font-size: 16px;
    word-wrap: break-word;
}

.account-list-text-bold {
    font-weight: bold;
}

.capitalize {
    text-transform: capitalize;
}

.account-list-float-right {
    text-align: right;
}

.account-details-col {
    padding-top: 25px;
}

.account-balance {
    padding-left: 30px;
}

.transaction-row {
    min-height: 28px;
    max-height: 28px;
}

.transaction-col {
    float: left;
    padding-top: 4px;
}

.transaction-header {
    padding: 8px 0;
    float: left;
    font-size: 16px;
    font-weight: bold;
}

.status-row {
    padding-left: 12px;
    min-width: 65px;
    width: 8%;
    font-style: italic;
    min-height: 24px;
    max-height: 24px;
}

.date-row {
    padding-left: 12px;
    min-width: 80px;
    width: 10%;
    min-height: 24px;
    max-height: 24px;
}

.desc-row {
    min-width: 205px;
    width: 30%;
    min-height: 24px;
    max-height: 24px;
    overflow-y: hidden;
}

.deposits-row {
    min-width: 105px;
    width: 15%;
    text-align: right;
    min-height: 24px;
    max-height: 24px;
}

.withdrawals-row {
    min-width: 105px;
    width: 15%;
    text-align: right;
    min-height: 24px;
    max-height: 24px;
}

.withdrawal-text {
    color: red !important;
}

.deposit-text {
    color: green !important;
}

.balance-row {
    min-width: 105px;
    width: 15%;
    text-align: right;
    min-height: 24px;
    max-height: 24px;
}

.show-more-transactions-container {
    width: 100%;
    min-width: 625px;
    height: 30px;
    float: left;
    text-align: center;
    border-bottom: 2px black solid;
    border-left: 2px black solid;
    border-right: 2px black solid;
}

.show-more-transactions {
    background-color: transparent;
    border: none;
    color: dodgerblue;
    font-size: 16px;
    margin-top: 0;
}

.show-more-transactions:hover {
    cursor: pointer;
}

svg {
    padding-right: 4px;
    vertical-align: text-bottom;
}

.sso-portal {
    min-width: 150px;
    width: 10%;
    float: left;
    margin-left: 20px;
}

.sso-menu-container {
    padding-bottom: 20px;
    float: left;
    width: 10%;
    min-width: 200px;
}

.sso-menu-button {
    min-width: 150px;
    width: 200px;
    height: 50px;
    border: 2px hidden;
    border-radius: 8px;
    background-color: var(--primary);
    color: var(--primaryText);
    box-shadow: none;
    transition-duration: 0.4s;
    margin-bottom: 10px;
    margin-top: 0;
    margin-right: 10px;
}

.sso-menu-button:hover {
    cursor: pointer;
    background-color: var(--secondary) !important;
    color: var(--pageButtons) !important;
    box-shadow: none;
}

.overview-portal {
    height: 100%;
    float: left;
    width: 300px;
    margin-right: 20px;
}

.requirements-initial {
    font-size: 16px;
}

.requirement-met {
    color: green;
    font-size: 16px;
}

.requirement-missing {
    color: red;
    font-size: 16px;
}

.add {
    background-color: rgb(5, 158, 5);
}

.add:hover {
    background-color: rgb(48, 184, 48);
}

.add:disabled {
    opacity: 0.65;
    cursor: not-allowed;
}

.delete {
    background-color: red;
    margin: 20px auto;
}

.delete:hover {
    background-color: rgb(253, 91, 91);
}

.delete:disabled {
    opacity: 0.65;
    cursor: not-allowed;
}

.edit-button-container {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    max-width: 500px;
    margin: auto;
    flex-wrap: wrap;
}

#Settings {
    margin: 20px auto;
    width: 90%;
    min-width: 292px;
}

.settings-tab-container {
    margin-top: 30px;
    padding-bottom: 10px;
}

.settings-flat-card {
    border: 1px solid var(--border) !important;
    border-radius: 8px !important;
    box-shadow: none !important;
}

.selected-setting {
    background-color: var(--pageButtons) !important;
    color: var(--secondaryText) !important;
}

.selected-setting:hover {
    background-color: var(--pageButtons) !important;
    color: var(--secondaryText) !important;
}

.account-info-container {
    padding: 20px;
    background-color: var(--primary);
    border: 2px hidden;
    border-radius: 8px;
    box-shadow: none;
}

.loaderHolder {
    position: absolute;
    left:0;
    top:0;
    width: 100vw;
    height: 100vh;
    overflow:hidden;
    background-color:rgba(255, 255, 255, 0.98);
    z-index:9999;
    display:flex;
    padding-top:15%;
    align-items: center;
    flex-direction: column;
}

.accounts-list-container {
    margin-left: 20px;
    max-width: 60%;
    min-width: 702px;
    height: 100%;
    background-color: var(--primary);
    padding: 20px;
    border: 2px hidden;
    border-radius: 8px;
    box-shadow: none;
}

.accounts-list {
    padding: 10px;
}

.refresh-button {
    border:none;
    background-color: transparent;
    color: #0055bb;
}

.refresh-button:hover {
    text-decoration: underline;
    cursor:pointer;
}

.users-list-container {
    display: block;
    margin-right: auto;
    background-color: var(--primary);
    padding: 20px;
    border: 2px hidden;
    border-radius: 8px;
    box-shadow: none;
}

.users-list-header {
    width: 100%;
    height: 40px;
    border: 2px black solid;
    background-color: var(--primary);
}

.users-header {
    padding: 8px 0;
    float: left;
    font-size: 16px;
    font-weight: bold;
}

.users-list {
    width: 100%;
    height: 450px;
    border-left: 2px black solid;
    border-right: 2px black solid;
    border-bottom: 2px black solid;
    overflow-y: scroll;

    background-color: var(--primary);
}

.users-list-col {
    float: left;
    padding-top: 4px;
    padding-left: 5px;
}

.users-list-row {
    height: 28px;
    cursor: pointer;
    transition-duration: .4s;
}

.user-name-row {
    padding-left: 8px;
    width: 40%;
}

.user-username-row {
    width: 40%;
}

.user-admin-row {
    width: 10%;
    text-align: center;
}

.reset-pass-button {
    margin-top: 0;
    background-color: var(--elementButtons);
    color: var(--elementButtonsText);
    border: 1px solid black;
}

.add-admin-button {
    margin-top: 0;
    background-color: var(--elementButtons);
    color: var(--elementButtonsText);
    border: 1px solid black;
}

.reset-pass-button-account {
    width: 150px;
    height: 40px;
    margin: 130px 0 0 0;
    background-color: var(--pageButtons);
    color: var(--pageButtonsText);
    border: 2px solid black;
}

.create-edit-form {
    display: block;
    background-color: var(--primary);
    border: 2px hidden;
    border-radius: 8px;
    box-shadow: none;
    padding: 20px;
}

.users-list-and-form {
    margin-top: 20px;
    justify-content: center;
}

.logo-container-settings {
    width: 70%;
    float: right;
    margin-right: 150px;
}

.bold-text {
    font-weight: bold;
}

.sub-header-size {
    font-size: 20px;
}

.account-info-data {
    font-weight: normal;
}

.account-card {
    border: 1px solid var(--secondary);
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 20px;
    margin-right: 10px;
    box-shadow: none !important;
}

.account-card-info {
    padding-top: 2px;
    padding-left: 8px;
}

.edit-account-button {
    margin-top: 0;
    background-color: transparent;
    border: none;
    padding-right: 0;
    vertical-align: center;
    max-width: 4%;
}

.delete-account-button {
    margin-top: 0;
    background-color: transparent;
    border: none;
    padding: 0 0 0 20px;
    vertical-align: center;
    max-width: 4%;
}

.account-card-input {
    text-align: left;
    width: 50%;
    margin-left: 4px;
}

.account-card-input:disabled {
    background-color: transparent;
    color: black;
    border: hidden;
}

.settings-page-header {
    height: 200px;
    width: 100%;
    padding-bottom: 2px;
    border-bottom: 2px black solid;

    background-color: var(--secondary);
    border-radius: 8px;
}

.navbar-header {
    text-align: center;
    display: block;
    font-size: 20px;
    font-weight: bold;
}

.users-list-search-container {
    width: 100%;
    display: flex;
    align-content: center;
}

.users-list-search {
    text-align: left;
    padding-left: 5px;
    height: 24px;
    border: 2px solid;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 4px;
    float: left;
}

.search-box-item {
    flex-grow: 1 !important;
    max-width: 100% !important;
}

.users-list-search:focus {
    outline: none;
    box-shadow: 0px 0px 4px var(--primary);
}

.users-list-search-button {
    float: left;
    margin: 10px;
    padding: 10px;
    min-width: 100px;
    background-color: var(--pageButtons);
    color: var(--pageButtonsText);
    border: 2px hidden;
    border-radius: 8px;
    transition-duration: 0.4s;
    cursor: pointer;
}

.users-list-search-button:hover {
    background-color: var(--hover);
}

.sso-linking-container {
    padding: 20px;
    margin-top: 20px;
    background-color: var(--secondary);
    border: 2px hidden;
    border-radius: 8px;
    box-shadow: none;
}

.sso-link {
    font-weight: bold;
    padding-top: 10px;
}

.sso-link-username {
    height: 25px;
    width: 80%;
    margin-top: 5px;
    text-align: left;
    padding-left: 5px;
}

.sso-link-password {
    height: 25px;
    margin-top: 10px;
    text-align: left;
    padding-left: 5px;
    margin-bottom: 10px;
    width: 80%;
}


/* STYLES FOR RDC SSO PAGES */
.rdcsso-form-container, .rdcsso-form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.rdcsso-button, .rdcsso-label{
    margin-top: 1rem;
    font-weight: bold;
}

/* -------------------------------------- */


.settings-page-button {
    background-color: var(--pageButtons) !important;
    color: var(--elementButtonsText) !important;
    margin-left: 6px !important;
    font-size: 12px !important;
    transition-duration: .4s;
}

.settings-page-button:hover {
    background-color: var(--hover) !important;
    color: var(--secondaryText) !important;
}

.header-card {
    width: 100%;
    max-width: 400px !important;
}

.header-card-no-shadow {
    width: 100%;
    background-color: transparent !important;
    box-shadow: none !important;
}

.user-table-card {
    overflow: visible !important;
    width: 100%;
}

.user-table {
    margin-top: 20px;
    width: 100%;
    scrollbar-width: thin;
    border: 1px solid var(--border);
    border-radius: 8px;
    max-height: 600px;
    box-shadow: none !important;
}

.banner {
    margin: 20px 0 20px 0 !important;
}

.user-table-header {
    background-color: var(--background) !important;
    font-weight: bold !important;
    color: var(--tableHeaderText) !important;
}

.divider {
    height: 1px !important;
    background-color: black !important;
    margin: 20px 0 20px 0 !important;
}

.settings-page-header {
    background-color: transparent;
}

.member-info-card {
    background-color: var(--secondary);
    border: 1px solid var(--border) !important;
    border-radius: 8px !important;
    box-shadow: none !important;
}

.account-info-card {
    max-width: 1050px;
}

.sso-info-card {
    max-width: 400px;
    border: 1px solid black;
}

.pass-toggle {
    border: none;
    background-color: transparent;
    padding-left: 4px;
    margin-top: 0;
}

.pass-toggle:hover {
    cursor: pointer;
}

.account-card-content {
    padding: 8px !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.is-admin {
    color: var(--secondary) !important;
}

.deleted-user {
    color: red !important;
}

.selected {
    color: var(--primary) !important;
    background-color: var(--secondary) !important;
}

.account-info-left {
    width: 30%;
    min-width: 231px;
    display: flex;
    flex-direction: column;
}

.account-info-page {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.account-list-proper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}

.reset-errors {
    justify-content: center;
    display: inline-block;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0px;
}

.iframe {
    margin-top: 20px;
    min-height: 500px;
    width: 100%;
}

.copy-button:hover {
    cursor: pointer;
}

.product-input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.product-input {
    height: 25px;
    width: 80%;
    margin: 5px;
    text-align: left;
    padding-left: 5px;
}

.horizontal-row>*,
.vertical-row>* {
    box-sizing: border-box;
}

input[type="text"] {
    box-sizing: border-box !important;
}

.mobile-menu-button-container {
    display: flex;
    flex-direction: column;
}

.mobile-menu-button {
    font-size: 30px !important;
}

.plaid-link-button-container {
    margin-top: 10px;
}

.custom-button,
.custom-save-button {
    background-color: var(--pageButtons) !important;
    border: 2px hidden !important;
    border-radius: 5px !important;
    text-align: center !important;
    color: var(--primary) !important;
    padding: 6px 8px !important;
    font-size: 12px !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    line-height: 21px !important;
    font-weight: 500 !important;
    cursor: pointer !important;
    justify-content: center !important;
    align-items: center !important;
    letter-spacing: 0.34284px !important;
    transition-duration: .4s;
}

.custom-save-button {
    width: 98%;
    padding: 12px 8px;
}

.custom-button:hover,
.custom-save-button:hover {
    background-color: var(--hover) !important;
    color: var(--secondaryText) !important;
}

.link-message {
    text-align: left;
    margin-bottom: 20px;
}

@media screen and (max-width:1850px) {
    .sso-menu-container {
        height: 50px;
        width: 100%;
        float: left;
    }

    .sso-portal {
        width: 100%;
    }

    .sso-menu-button {
        margin-right: 10px;
        background-color: var(--primary);
        color: var(--primaryText);
    }

    .account-info-card {
        max-width: 1375px;
    }
}

@media screen and (max-width:1445px) {
    .menu-container {
        float: none;
    }

    .page-error-body {
        width: 50%;
    }
}

@media screen and (max-width:1280px) {
    .menu-container {
        float: none;
    }

    .page-error-body {
        width: 50%;
    }
}

@media screen and (max-width: 960px) {
    .pagination-select {
        margin-right: 0px;
    }

    .pagination-buttons {
        padding-left: 3px !important;
        padding-right: 3px !important;
    }

    .page-error-body {
        width: 75%;
    }

    .reset-api-error {
        width: 100%
    }

    .faq-textarea {
        margin: 10px auto 0px auto;
    }

    .mobile-dashboard-container {
        margin-top: 20px !important;
    }

    .toolbar {
        text-align: center;
        float: none;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
    }

    .welcome-message-container {
        text-align: center;
        float: none;
    }

    .welcome-message {
        margin: 0;
    }

    .settings-tab-container {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .users-list-search {
        display: block;
        margin: 0 auto 0px auto;
        height: 25px;
        width: 80%;
        max-width: 300px;
        font-size: 20px;
        padding: 12px;
        border: 2px solid;
        border-radius: 8px;
        transition-duration: 0.4s;
        text-align: left;
    }

    .users-list-search-button {
        display: block;
        margin: 20px;
        height: 50px;
        min-width: 100px;
        padding-left: 10px;
        padding-right: 10px;
        border: 2px hidden;
        border-radius: 8px;
        font-size: 18px;
        background-color: var(--pageButtons);
        color: var(--pageButtonsText);
        transition-duration: 0.4s;
        cursor: pointer;
    }

    .account-card {
        width: 100%;
    }
}

@media screen and (max-width:680px) {

    .no-accounts,
    .no-transactions {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

@media screen and (max-width:600px) {
    .page-error {
        width: 100%;
    }

    .page-error-body {
        width: 75%;
    }

    .edit-button-container {
        flex-direction: column !important;
    }

    .settings-tab-container {
        margin-left: 5px;
        flex-direction: column !important;
        align-items: center;
    }

    .search-button-container {
        flex-direction: row !important;
    }

    .settings-tab {
        max-width: 100%;
        width: 100%;
    }

    .sso-menu-button {
        width: 100%;
        background-color: var(--primary) !important;
        color: var(--primaryText) !important;
    }
}

.no-accounts,
.no-transactions {
    text-align: center;
    font-weight: bold;
    color: red;
}

.no-accounts {
    padding-bottom: 10px;
}

.horizontal-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.vertical-row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.horizontal-row>div {
    display: flex;
    flex: 1;
}

.vertical-row>div {
    display: flex;
    flex: 1;
}

.mr-10 {
    margin-right: 10px;
}

.ml-10 {
    margin-left: 10px;
}

.w-100 {
    width: 100%;
}

.p-24 {
    padding: 0 24px;
}

.horizontal-row>.input-group {
    margin-right: 10px !important;
}

.input-group {
    display: flex;
    flex-direction: column;
    width: 100%;
}

input[type="text"] {
    outline: none !important;
    transition: border .4s;
}

input[type="text"]:hover {
    cursor: text;
}

input[type="text"]:focus {
    border: 1px solid var(--pageButtons);
}

.input-group,
.input-group>* {
    box-sizing: border-box;
}

.input-header {
    font-weight: bold;
    margin-bottom: 5px;
}

.input-box {
    border-radius: 8px;
}

.input-box>input,
.input-box>select {
    width: 100%;
    border-radius: 4px;
    border: 1px solid var(--border);
    color: #3c3c3c;
    padding: 12px 12px;
    font-size: 16px;
}

hr {
    border: none;
    margin-top: 24px;
    height: 0;
    border-bottom: 1px solid #BCBCBC;
}

.mb-20 {
    margin-bottom: 24px;
}

input[type="color"] {
    padding: 0;
    border: 0;
    box-shadow: 0;
}

input[type="color"]:hover {
    cursor: pointer;
}

.page-shadow {
    position: fixed;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    background-color: rgba(0, 0, 0, 0.73489);
    top: 0;
    left: 0;
}

.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    z-index: 1002;
    align-items: center;
    justify-content: center;
}

.modal-box {
    position: relative;
    width: 40%;
    border-radius: 8px;
    border: 1px solid var(--border);
    background-color: var(--background);
    padding: 40px;
}